;function openIframe(file_name, file_path)
{
    $.modal({
        title: file_name,
        url: file_path,
        useIframe: true,
        width: 1300,
        height: 800
    });
}

;function formatCurrency(money) {
    return ('$' + parseFloat(money, 10).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString().slice(0,-2));
}